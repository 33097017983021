<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  components: {  },
  mounted(){
  },
  created() {
    this.windowResize()
  },
  methods: {
    windowResize() {
      //屏幕分辨率为1920*1080
      const loadFull = () => {
        let width = window.innerWidth / 1920;
        let height = window.innerHeight / 945;
        // document.body.style.cssText = `transform:scale(${width},${height});transform-origin:left top;background-size: 100% 100%;`;
      };
      loadFull();
      window.addEventListener("resize", loadFull);
    }
  }
 };
</script>
<style lang="scss">
*{
  font-family: PingFang SC, PingFang SC,serif !important;
}
html, body, #app{
  margin: 0 !important;
  padding: 0 !important;
  width: 1920px;
  height: 1080px;
}

.el-select-dropdown {

}

.tdt-bottom{
  display: none !important;
}
html {
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
}
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  family: 'PingFang SC', 'SF Pro Text', 'Arial', sans-serif;
}
#app {
  width: 100%;
  height: 100%;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
